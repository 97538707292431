import { l } from '@woltair/translations-package-fe'
import { get } from 'svelte/store'
import { page } from '$app/stores'
import { escapeHtml } from '$lib/utils'
import { ORGANIZATION } from './consts'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
function t(key: string, values?: Record<string, string>) {
	if (!key) return
	let language = null
	try {
		const $page = get(page)
		if ($page.data.organization.lang) {
			language = $page.data.organization.lang.toLowerCase()
		} else {
			language ??= ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].hreflang
		}
	} catch (e) {
		language = ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].hreflang
	}
	return l(language.toLowerCase(), key, values)
}

function tTag(key: string, values?: Record<string, string>) {
	if (!key) return
	if (!values) {
		return t(key)
	}

	const sanitizedValue: Record<string, string> = {}
	Object.entries(values).forEach(([key, value]) => {
		sanitizedValue[key] = escapeHtml(value)
	})

	return t(key, sanitizedValue)
}

export { t, tTag }
